// safeLocalStorage
//
// This just catches and logs the errors that come up in incognito mode
// when localStorage is unavailable. If you care about the errors, you
// should just use localStorage and catch them. Usually we don't care.

function delegate (method, ...args) {
  try {
    return localStorage[method](...args)
  }
  catch (error) {
    console.error('LocalStorage error, possibly due to incognito mode. (Ignoring.)', error)
  }
}

const safeLocalStorage = {
  getItem: key => delegate('getItem', key),
  setItem: (key, val) => delegate('setItem', key, val),
  removeItem: key => delegate('removeItem', key),
  clear: () => delegate('clear'),
}

export default safeLocalStorage
