/**
 * Generic API client with retry and failure-logging
 */
import Axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry'
import { v4 as uuid } from 'uuid'
// We can't list @grantstreet/govhub-vue as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import Session from '@grantstreet/psc-vue/utils/session.js'
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import { handleTurnstileChallenge } from '@grantstreet/psc-vue/utils/cloudflare-turnstile.js'
import { SentryMessageFunction } from '@grantstreet/sentry'
import { getCircularReplacer } from '@grantstreet/psc-js/utils/objects.ts'

type GetJwtFunction = () => (string | undefined) | Promise<(string | undefined)>

type AxiosErrorWithMetadata = AxiosError & {
  suppressLogging?: boolean
  alreadyLogged?: boolean
}

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface AxiosRequestConfig {
    turnstileProtected?: boolean
  }
}

export default class GenericApiClient {
  axios: AxiosInstance
  jwt?: string
  getJwt?: GetJwtFunction
  app: string
  exceptionLogger: SentryMessageFunction
  baseUrl: (() => string) | string
  sameDomainUrl: (() => string) | string
  getLocale?: () => string
  skipCorrelationId = false

  constructor (opts: {
    /**
     * An auth token
     */
    jwt?: string

    /**
     * A function that returns the JWT.
     */
    getJwt?: GetJwtFunction

    /**
     * Used in creating X-Correlation-ID header
     */
    app?: string

    /**
     * A custom exception logger - this is used to send errors to sentry
     */
    exceptionLogger: SentryMessageFunction

    /**
     * A function that returns a base URL (or a raw string URL) to be used for
     * API requests.
     */
    baseUrl?: (() => string) | string

    /**
     * A function that returns a URL from the same domain as the hostname
     * (or a raw string URL) to be used for API requests.
     */
    sameDomainUrl?: (() => string) | string

    /**
     * A function that returns the user's language
     */
    getLocale?: () => string

    /**
     * Flag used to skip auto-adding a generated correlation ID to every
     * request
     */
    skipCorrelationId?: boolean
  }) {
    this.axios = Axios.create({
      // 60 seconds
      timeout: 60 * 1000,
      turnstileProtected: false,

      validateStatus: function (status) {
        // Context: CloudFlare Managed Challenge returns a 403 which we want
        // to intercept with handleTurnstileChallenge. The handleTurnstileChallenge
        // is in an axios response interceptor which runs after validateStatus.
        // In order to implement CF Turnstile, we need to override the default
        // validateStatus from axios (return (status >= 200 && status < 300)) to
        // allow 403s through. We manually reject other 403s using an axios interceptor
        return (status >= 200 && status < 300) || status === 403
      },
    })

    this.app = opts.app || 'govhub-ui'

    this.baseUrl = opts.baseUrl || ''
    this.sameDomainUrl = opts.sameDomainUrl || ''

    this.jwt = opts.jwt
    this.getJwt = opts.getJwt

    this.getLocale = opts.getLocale

    if (!opts.exceptionLogger) {
      throw new TypeError('An exceptionLogger is required')
    }

    this.exceptionLogger = opts.exceptionLogger

    axiosRetry(this.axios, this.axiosRetryOpts())

    // Intercept all requests to add some HTTP headers
    this.axios.interceptors.request.use(async (
      config: InternalAxiosRequestConfig & {
        accessToken?: string
      },
    ) => {
      // Append Authorization header, using current JWT
      const header = await this.getAuthHeader(config?.accessToken)
      if (header) {
        config.headers.Authorization = header
      }
      // Add the user's chosen language to all requests
      if (this.getLocale) {
        config.headers['Accept-Language'] = this.getLocale()
      }
      if (!opts.skipCorrelationId) {
        // Create a request ID and add the session ID. We allow skipping because
        // some backends may not support this Cross-Origin.
        const correlationGroup = Session.id
        config.headers['X-Correlation-ID'] = `${this.app}:${uuid()}+${correlationGroup}`
      }

      return config
    })

    this.axios.interceptors.response.use(async (response) => {
      // If the endpoint is not enabled for turnstile protection, just return the response
      if (!response.config?.turnstileProtected) {
        return response
      }

      if (response.headers?.['cf-mitigated'] === 'challenge') {
        await handleTurnstileChallenge()

        // retry the original request with the cf_clearance Cookie
        return this.axios(response.config)
      }

      return response
    })

    this.axios.interceptors.response.use(async (response) => {
      if (response.status === 403) {
        // Since we overwrote the validateStatus default
        // to allow for 403s, we need to manually reject
        // other 403s that are not Manage Challenges to
        // preserve the status quo
        throw new AxiosError(
          'Request forbidden',
          '403',
          response.config,
          response.request,
          response,
        )
      }

      return response
    })

    // Log some API errors to Sentry
    this.axios.interceptors.response.use(response => response, error => this.handleError(error))
  }

  /**
   * Returns an Authorization Bearer header for the request. This defaults to
   * using an accessToken passed explicitly via a request's `opts` param:
   *
   *   api.get('/url', { accessToken: someJwt }))
   *
   * Alternatively you can pass a JWT-getting function to the API constructor
   * and it will be called for all API requests:
   *
   *   new ApiClient({ getJwt: () => refreshableJwt })
   *
   * Or initialize with a static string (not recommended if you want your JWTs
   * to be refreshable):
   *
   *   new ApiClient({ jwt: someJwt })
   *
   * Subclasses that don't use the 'Bearer' prefix should override this.
   */
  async getAuthHeader (accessToken = '') {
    const token = await (accessToken || this.getJwt?.() || this.jwt)
    return token ? ` Bearer ${token}` : null
  }

  axiosRetryOpts (): IAxiosRetryConfig {
    return {
      // 7 retries results in about 30s of wait
      // (plus whatever time was actually spent on the request)
      // before we stop retrying and show the user an error.
      // At that point they can retry their request manually.
      retries: 7,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: false,
      retryCondition: this.axiosRetryCondition,
    }
  }

  axiosRetryCondition (error: AxiosError): boolean {
    // TODO: Might consider the implications of retying non-idempotent requests
    // and whether we'd like to stop that
    if (!error.config) {
      // Cannot determine if the request can be retried
      return false
    }

    const status = error.response && error.response.status
    if (status && status >= 400 && status < 500) {
      // Don't retry "Bad Request", "Forbidden", "Unprocessable Entity", etc.
      return false
    }

    if (status && status === 500) {
      // This is debatable, but it's likely enough that 500 means something is
      // actually wrong with our request that we are better off not retrying here
      // either.
      return false
    }

    // Everything else is okay to retry
    return true
  }

  handleError (rawError: AxiosError) {
    // Some error objects (like DOMExceptions) have readonly props (like
    // `name`), so we have to wrap them in a mutable object to change them.
    let error: AxiosErrorWithMetadata = Object.create(rawError)

    const url = error.config ? error.config.url : 'N/A'
    error.message = `API request error (URL ${url}): `

    if (error.response) {
      // The request was made and the server responded with a status code that
      // falls out of the range of 2xx
      error.message += `Server returned ${error.response.status} error with data: ${JSON.stringify(error.response.data, getCircularReplacer())}`
    }
    else if (error.request) {
      // The request was made but no response was received
      error.message += `No server response. Request: ${JSON.stringify(error.request, getCircularReplacer())}`
    }
    else {
      // Something happened in setting up the request that triggered an Error
      error.message += `Error setting up request. Error: ${JSON.stringify(error, getCircularReplacer())}`
    }

    if (this.isException(error)) {
      error = this.handleException(error)
    }
    else {
      error.suppressLogging = true
    }

    return Promise.reject(error)
  }

  // This generic client thinks every truthy value is an exception
  isException (error: AxiosError) {
    return Boolean(error)
  }

  handleException (error: AxiosErrorWithMetadata) {
    console.error(error)

    // Don't report network errors or error codes 400-599 to Sentry. Except
    // 404s. Our backends think 404s are a normal case, but if we're hitting an
    // "unknown" endpoint, something has gone wrong.
    if (
      (
        error?.code === 'ERR_NETWORK' ||
        error.response?.status === 0
      ) || (
        error.response?.status &&
        error.response?.status >= 400 &&
        error.response?.status !== 404 &&
        error.response?.status < 600
      )
    ) {
      error.suppressLogging = true
    }

    this.exceptionLogger?.(error)

    return error
  }

  get (path: string, opts?: object) {
    return this.axios.get(this.url(path), opts)
  }

  head (path: string, opts?: object) {
    return this.axios.head(this.url(path), opts)
  }

  post (path: string, data: object|string, opts?: object) {
    return this.axios.post(this.url(path), data, opts)
  }

  postSameDomain (path: string, data: object|string, opts?: object) {
    // Cleanup ticket is PSC-23000 if we want to remove this same domain routing
    return this.axios.post(this.domainUrl(path), data, opts)
  }

  patch (path: string, data: object, opts?: object) {
    return this.axios.patch(this.url(path), data, opts)
  }

  put (path: string, data: object, opts?: object) {
    return this.axios.put(this.url(path), data, opts)
  }

  delete (path: string, opts?: object) {
    return this.axios.delete(this.url(path), opts)
  }

  url (path: string) {
    if (this.baseUrl) {
      const base = typeof this.baseUrl === 'function'
        ? this.baseUrl()
        : this.baseUrl
      const slash = path.match(/^\//) ? '' : '/'
      return base + slash + path
    }
    else {
      return path
    }
  }

  domainUrl (path: string) {
    if (this.sameDomainUrl) {
      const base = typeof this.sameDomainUrl === 'function'
        ? this.sameDomainUrl()
        : this.sameDomainUrl
      const slash = path.match(/^\//) ? '' : '/'
      return base + slash + path
    }
    else {
      return path
    }
  }

  getBaseUrl () {
    return typeof this.baseUrl === 'function' ? this.baseUrl() : this.baseUrl
  }
}

/*
 * This is the base class for "mock" API clients. It just
 * contains some helpers related to mimicking axios's behavior.
 */
export class MockApiClient {
  $response (
    data: {
      data?: object
      opts?: object
    },
    opts?: object,
  ) {
    // Allows us to pass everything in a params object which is a very
    // convenient pattern
    if (data.data && data.opts && !opts) {
      ;({ data, opts } = data)
    }
    else {
      opts = {}
    }
    return new Promise((resolve) => {
      resolve({
        data,
        status: 200,
        statusText: 'OK',
        headers: {
          'Content-Type': 'application/json',
        },
        config: {},
        request: {},
        ...opts,
      })
    })
  }
}
